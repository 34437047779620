import { Rules } from '~/store/interfaces'

export interface PageMeta {
  currentPage: number,
  offset?: number,
  pageCount?: number,
  perPage?: number,
  totalCount?: number
}

export type CreateItem<T> = Omit<T, 'id' | 'createdAt' | 'updatedAt'>

export interface Paginator extends PageMeta {
  pageSize: number
  currentPage: number
  totalItems: number
  totalPages: number
}

export interface ResponseData<T> {
  data: T[]
  paginator: Paginator
  meta?: PageMeta
}

export type ValidatorParams<T, K extends keyof T> = {
  [key in K]: Rules[] | object;
};

/**
 * Значения порядка сортировки
 */
export enum ORDER {
  ASC = 'asc',
  DESC = 'desc',
}

/**
 * Параметры пагинации
 */
export interface PageParams<T> {
  page?: number;
  pageSize?: number;
  sort?: keyof T;
  order?: ORDER;
  apiUrlPrefix?: string;
  [key: string]: any;
}

/**
 * Объект фильтров списка сущностей
 */
export type IFilters<T, KEYS extends keyof Partial<T>> = Partial<Pick<T, KEYS>> & Record<string, any>;

/**
 * Плагин модуля стора
 */
export interface ModulePlugin<
  T,
  I extends keyof T,
  KEYS,
  FILTERS_KEYS extends keyof Partial<T> = keyof Partial<T>
> {
  /**
   * Фильтры списка сущностей
   */
  filters: Partial<Pick<T, FILTERS_KEYS>>;

  /**
   * Список сущностей
   */
  list: ResponseData<T>;

  /**
   * Сущность
   */
  item: T;

  /**
   * Сущность по идентификатору
   * @param identifier идентификатор
   */
  itemByIdentifier(identifier: T[I]): T | undefined;

  /**
   * Сброс списка сущностей
   */
  resetList(): void;

  /**
   * Сброс сущности
   */
  resetItem(): void;

  /**
   * Установка значения фильтра по ключу
   * @param key ключ фильтра
   * @param value значение фильтра
   */
  setFilterByKey<K extends FILTERS_KEYS>(
    key: K,
    value: IFilters<T, FILTERS_KEYS>[K]
  ): void;

  /**
   * Сброс фильтров списка сущностей
   */
  resetFilters(): void;

  /**
   * Установка свойства сущности по ключу
   * @param key ключ свойства
   * @param value значение свойства
   */
  setItemProp<K extends keyof T, V extends T[K]>(key: K, value: V): void;

  /**
   * Установка списка сущностей
   * @param data список сущностей
   */
  setList (data: ResponseData<T>): void

  /**
   * Установка сущности
   * @param data сущность
   */
  setItem (data: T): void

  /**
   * Установка вложенного свойства сущности по массиву ключей в порядке вложенности
   * @param value значение свойства
   * @param keys массив ключей
   */
  setItemPropByKeys(value: any, ...keys: (KEYS | number)[]): void;

  /**
   * Запрос на получение списка сущностей
   * @param pageParams параметры пагинации
   */
  getList(
    pageParams?: PageParams<T>, save?: boolean
  ): Promise<ResponseData<T>>;

  /**
   * Запрос на получение сущности по идентификатору
   * @param identifier идентификатор
   */
  getItem(identifier: T[I], save?: boolean): Promise<T>;

  /**
   * Запрос на создание сущности
   */
  createItem(): Promise<T>;

  /**
   * Запрос на изменение сущности по идентификатору
   * @param identifier идентификатор
   */
  editItem(identifier: T[I], item?: T): Promise<T | undefined>;

  /**
   * Запрос на удаление сущности по идентификатору
   * @param identifier идентификатор
   */
  removeItem(identifier: T[I]): Promise<T>;
}

/**
 * Конфигурация модуля стора
 */
export interface ModuleConfig<T, I extends keyof T> {
  /**
   * Роут апи модуля
   */
  apiUrl: string;
  /**
   * Ключ свойства идентификатора сущности
   */
  identifier: I;
  /**
   *  Префикс адреса, если используется внешнее апи
   */
  apiUrlPrefixEnv?: string;
}
