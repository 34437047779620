import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { FormError, PageParams, defaultData, ResponseData } from '../../interfaces'
import { Manager, Filters } from './interfaces'
import { $axios } from '~/utils/api'

@Module({
  name: 'managers',
  stateFactory: true,
  namespaced: true
})

export default class ManagersModule extends VuexModule {
  managersValue: ResponseData<Manager> = defaultData

  filtersValue: Filters = {
    type: undefined
  }

  managerValue: Manager = {
    active: true,
    createdAt: 0,
    email: '',
    fio: '',
    id: 0,
    phone: '',
    socialMedia: {
      telegram: '',
      viber: '',
      whatsapp: ''
    },
    updatedAt: 0
  }

  // ? ______________ getters ______________
  /**
   * * Получить значение тикета
   */
  get managers (): ResponseData<Manager> {
    return this.managersValue
  }

  get manager (): Manager {
    return this.managerValue
  }

  /**
   * * Получить фильтры групп статусов
   */
  get filters (): Filters {
    return this.filtersValue
  }

  // ? ______________ setters ______________
  /**
   * * Установить значение тикета
   * @param filters - значение тикета
   */
  @Mutation
  setManagersValue (val: ResponseData<Manager>) {
    this.managersValue = val
  }

  @Mutation
  setManagerValue (val: any) {
    this.managerValue = val
  }

    /**
   * * Установить значения фильтров
   * @param filters - значения фильтров
   */
    @Mutation
  setFilters (filters: Filters) {
    this.filtersValue = filters
  }

    /**
   * * Сбросить значения фильтров
   */
    @Mutation
    resetFilters () {
      this.filtersValue = {
        type: undefined
      }
    }

    @Mutation
    resetManagerValue () {
      this.managerValue = {
        active: true,
        createdAt: 0,
        email: '',
        fio: '',
        id: 0,
        phone: '',
        socialMedia: {
          telegram: '',
          viber: '',
          whatsapp: ''
        },
        updatedAt: 0
      }
    }

    // ? ______________________________________actions______________________________________
  /**
   * *
   * @param id заказа
   */
  @Action({
    rawError: true,
    commit: 'setManagersValue'
  })
    async getManagers ({
      siteApiUrl,
      pageParams
    }: {
    siteApiUrl: string;
    pageParams: PageParams | undefined
  }) {
      try {
        const { data } = await $axios.get(`${siteApiUrl}/managers/v1`, { params: pageParams, ...this.filters })
        const response: Manager[] = data
        return response
      } catch (error) {
        throw new FormError(error as AxiosError<FormError>)
      }
    }

    @Action({
      rawError: true,
      commit: 'setManagerValue'
    })
  async getManager ({
    siteApiUrl,
    id
  }: {
        siteApiUrl: string;
        id: number
      }) {
    try {
      const { data } = await $axios.get(`${siteApiUrl}/managers/v1/${id}`)
      const response: Manager = data.data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  @Action({
    rawError: true
  })
    async createManager ({
      siteApiUrl,
      manager
    }: {
    siteApiUrl: string;
    manager: Manager;
  }) {
      try {
        const { data } = await $axios.post(`${siteApiUrl}/managers/v1`, manager)
        const response: Manager = data
        return response
      } catch (error) {
        throw new FormError(error as AxiosError<FormError>)
      }
    }

    @Action({
      rawError: true
    })
  async editManager ({
    siteApiUrl,
    manager,
    id
  }: {
        siteApiUrl: string;
        manager: Manager;
        id: number
      }) {
    try {
      const { data } = await $axios.put(`${siteApiUrl}/managers/v1/${id}`, manager)
      const response: Manager = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  @Action({
    rawError: true
  })
    async deleteManager ({
      siteApiUrl,
      id
    }: {
      siteApiUrl: string;
      id: number
    }) {
      try {
        const { data } = await $axios.delete(`${siteApiUrl}/managers/v1/${id}`)
        const response: Manager = data
        return response
      } catch (error) {
        throw new FormError(error as AxiosError<FormError>)
      }
    }
}
