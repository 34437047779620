const productFields = [
  { name: 'active' },
  { name: 'category_id' },
  { name: 'category_title' },
  { name: 'created_at', alias: 'createdAt' },
  { name: 'discount_groups' },
  { name: 'hide_price' },
  { name: 'id' },
  { name: 'images' },
  { name: 'images_count' },
  { name: 'sort' },
  { name: 'stickers' },
  { name: 'options' },
  { name: 'title' },
  { name: 'total_options_values' },
  { name: 'vendor_code' }
]

enum ArrayTypes {
  'discount_groups',
  'total_options_values',
  'stickers'
}

export {
  productFields,
  ArrayTypes
}
