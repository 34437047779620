import { Module, Mutation } from 'vuex-module-decorators'
import { TaskOption } from './interfaces'
import { ModuleConfig, ResponseData } from '~/types/store/store-base-type'
import BaseModule from '~/types/store/store-base'

@Module({
  stateFactory: true,
  namespaced: true,
  name: 'taskOptions'
})
export default class TaskOptionsModule extends BaseModule<TaskOption, 'id', keyof TaskOption> {
  protected config: ModuleConfig<TaskOption, 'id'> = {
    apiUrl: '/task/option',
    identifier: 'id'
  }

  /**
     * Установка списка сущностей
     * @param data список сущностей
     */
    @Mutation
  setList (data: ResponseData<TaskOption>) {
    this.listData = { ...data, data: data.data.sort((a, b) => a.params.type > b.params.type ? -1 : 0) }
  }

    protected itemData: TaskOption = {
      id: 0,
      name: '',
      params: {
        type: '',
        statuses: []
      },
      roles: [],
      createdAt: 0,
      updatedAt: 0
    }

    @Mutation
    resetItem () {
      this.itemData = {
        id: 0,
        name: '',
        params: {
          type: '',
          statuses: []
        },
        roles: [],
        createdAt: 0,
        updatedAt: 0
      }
    }
}
