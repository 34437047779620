import { Component, Vue } from 'nuxt-property-decorator'
import { Group } from './interfaces'

@Component
export default class GroupsParamsMixin extends Vue {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    next()
  }

  /**
   * * Запрос на получение списка групп статусов заказа
   * @param param - параметры пагинатора (необязательные)
   * @returns список групп статусов заказа
   */
  async getGroups (params?: {page?: number, pageSize?: number, sort?: string, order?: string}) {
    try {
      this.$wait.start('getGroups')
      return await this.$groupsParams.getGroups({
        page: +(params?.page || 1),
        pageSize: params?.pageSize,
        sort: params?.sort,
        order: params?.order
      })
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getGroups')
    }
  }

  async getGroup (id: number) {
    try {
      this.$wait.start('getGroup')
      return await this.$groupsParams.getGroup(id)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getGroup')
    }
  }

  /**
   * * Запрос на создание группы
   * @returns группа статуса заказов
   */
  async createGroup (group: Group) {
    try {
      this.$wait.start('createGroup')
      const data = await this.$groupsParams.createGroup(group)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: 'Группа создана'
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('createGroup')
    }
  }

  /**
   * * Запрос на создание группы
   * @returns Группа статуса заказов
   */
  async editGroup (id: number, group: any) {
    try {
      this.$wait.start('editGroup')
      const data = await this.$groupsParams.editGroup(id, group)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: 'Группа изменена'
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('editGroup')
    }
  }

  /**
   * * Запрос на создание группы
   * @returns Группа статуса заказов
   */
  async deleteGroup (id: number) {
    try {
      this.$wait.start('deleteGroup')
      const data = await this.$groupsParams.deleteGroup(id)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: 'Группа удалена'
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('deleteGroup')
    }
  }
}
