import { Task } from './interfaces'
import TasksModule from './store'
import BasePlugin from '~/types/store/plugin-base'
import { IFilters, PageParams, ResponseData } from '~/types/store/store-base-type'

export default class Tasks extends BasePlugin<Task, 'id', keyof Task, TasksModule> {
  get userTasks (): ResponseData<Task> {
    return this.module.userTasks
  }

  get userFilters () {
    return this.module.userFilters
  }

  set userTasks (data: ResponseData<Task>) {
    this.module.setUserTasks(data)
  }

  set userFilters (data: IFilters<Task, keyof Task>) {
    this.module.setUserFilters(data)
  }

  async getUserTasks (pageParams?: PageParams<Task>, save = true) {
    const data = await this.module.getUserTasksList(pageParams)
    if (save) {
      this.module.setUserTasks(data)
    }
    return data
  }
}
