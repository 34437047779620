
import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component({
  name: 'UiFormItem'
})
export default class UiFormItem extends Vue {
  @Prop({
    type: Array,
    required: false,
    default: () => []
  })
    fields: any

  @Prop({
    type: Object,
    required: false,
    default: () => []
  })
    rules: any

  @Prop({
    type: Object,
    required: false,
    default: () => {}
  })
    currentStore: any

  @Prop({
    type: String,
    required: false,
    default: () => {}
  })
    currentGetter: any

  @Prop({
    type: Function,
    required: false,
    default: () => {}
  })
    getStringType: any

  @Prop({
    type: Object,
    required: false,
    default: () => {}
  })
    pickerOptionsDates: any

  @Prop({
    type: String,
    required: false,
    default: () => ''
  })
    stringCurrentFields: any

  get getFields () {
    return this.currentStore?.[this.currentGetter] || {}
  }

  get birthDate () {
    const birthDate = this.getFields?.birthDate
    if (birthDate && typeof birthDate === 'string' && birthDate.includes('-')) {
      const [day, month, year] = birthDate.split('-').map(part => parseInt(part, 10))
      // Создать объект Date
      const dateObject = new Date(year, month - 1, day)
      // Получить Unix-время в секундах
      const unixTime = Math.floor(dateObject.getTime())
      return unixTime
    }
    return birthDate
  }

  set birthDate (event: any) {
    const dateObject = new Date(event)
    // Извлечь компоненты даты
    const day = String(dateObject.getDate()).padStart(2, '0')
    const month = String(dateObject.getMonth() + 1).padStart(2, '0') // Месяцы нумеруются с нуля
    const year = dateObject.getFullYear()
    // Форматировать строку даты
    const formattedDate = `${day}-${month}-${year}`
    this.currentStore[this.currentGetter] = { ...this.getFields, birthDate: formattedDate }
  }

  onChange (key: string, value: any) {
    this.$emit('changeField', { key, value })
  }
}

