import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { DefaultDeliveryRegion, Params } from './interfaces'
import { defaultData, FormError, PageParams, ResponseData, ValidatorParams } from '~/store/interfaces'
import { $axios } from '~/utils/api'
import validatorsPattern from '~/utils/validators'

@Module({
  name: 'defaultDeliveryRegion',
  stateFactory: true,
  namespaced: true
})
export default class defaultDeliveryRegionModule extends VuexModule {
  defaultDeliveryRegionValue: DefaultDeliveryRegion = {
    id: 0,
    code: '',
    name: '',
    price: 0,
    minDays: 0,
    maxDays: 0,
    optionId: 0
  }

  defaultDeliveryRegionsValue: ResponseData<DefaultDeliveryRegion> = defaultData

  // ? ______________ getters ______________

  get defaultDeliveryRegion (): DefaultDeliveryRegion {
    return this.defaultDeliveryRegionValue
  }

  get defaultDeliveryRegionById () {
    const regions = this.defaultDeliveryRegionsValue.data
    return function (id: number): DefaultDeliveryRegion | undefined {
      return regions.find(region => region.id === id)
    }
  }

  get defaultDeliveryRegions (): ResponseData<DefaultDeliveryRegion> {
    return this.defaultDeliveryRegionsValue
  }

  get validateData (): boolean {
    return !!this.defaultDeliveryRegion.code
  }

  get validators (): ValidatorParams {
    return {
      name: [{
        required: true,
        pattern: validatorsPattern.stringEmpty,
        message: 'Введите название региона для доставки',
        trigger: ['blur']
      }],
      code: [{
        required: true,
        message: 'Введите код региона для доставки',
        trigger: ['blur']
      },
      {
        pattern: validatorsPattern.regionCode,
        message: 'Введите корректный код региона для доставки',
        trigger: ['change', 'blur']
      }],
      price: [{
        required: true,
        message: 'Введите стоимость доставки в регион',
        trigger: ['blur']
      },
      {
        pattern: validatorsPattern.prices,
        message: 'Введите корректную стоимость доставки в регион',
        trigger: ['change', 'blur']
      }],
      minDays: [{
        required: true,
        message: 'Введите минимальный срок доставки в регион',
        trigger: ['blur']
      },
      {
        pattern: validatorsPattern.naturalNumbers,
        message: 'Введите корректный минимальный срок доставки в регион',
        trigger: ['change', 'blur']
      }],
      maxDays: [{
        required: true,
        message: 'Введите максимальный срок доставки в регион',
        trigger: ['blur']
      },
      {
        pattern: validatorsPattern.naturalNumbers,
        message: 'Введите корректный максимальный срок доставки в регион',
        trigger: ['change', 'blur']
      }]
    }
  }

  // ? ______________ setters ______________

  @Mutation
  setDefaultDeliveryRegion (defaultDeliveryRegion: DefaultDeliveryRegion) {
    this.defaultDeliveryRegionValue = defaultDeliveryRegion
  }

  @Mutation
  setDefaultDeliveryRegions (defaultDeliveryRegions: ResponseData<DefaultDeliveryRegion>) {
    this.defaultDeliveryRegionsValue = defaultDeliveryRegions
  }

  @Mutation
  resetDefaultDeliveryRegion () {
    this.defaultDeliveryRegionValue = {
      id: 0,
      code: '',
      name: '',
      price: 0,
      minDays: 0,
      maxDays: 0,
      optionId: 0
    }
  }

  // ? ______________ actions ______________

  @Action({
    rawError: true,
    commit: 'setDefaultDeliveryRegions'
  })
  async getDefaultDeliveryRegions (args: {params: Params, siteDomain: string}) {
    try {
      const { params, siteDomain } = args
      const { data } = await $axios.get(`${siteDomain}/api/delivery/v1/options/${params.optionId}/regions`, { params: { ...params } })
      const response: ResponseData<DefaultDeliveryRegion> = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  @Action({
    rawError: true,
    commit: 'setDefaultDeliveryRegion'
  })
  async getDefaultDeliveryRegionById (args: {regionId: number, siteDomain: string}) {
    try {
      const { regionId, siteDomain } = args
      const { data } = await $axios.get(`${siteDomain}/api/delivery/v1/regions/${regionId}`)
      const response: DefaultDeliveryRegion = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  @Action({
    rawError: true
  })
  async createDefaultDeliveryRegion (args: {optionId: number, siteDomain: string}) {
    try {
      const { optionId, siteDomain } = args
      const { data: { data } } = await $axios.post(`${siteDomain}/api/delivery/v1/options/${optionId}/regions`, this.defaultDeliveryRegion)
      const response: DefaultDeliveryRegion = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  @Action({
    rawError: true
  })
  async editDefaultDeliveryRegion (siteDomain: string) {
    try {
      const { id, ...newDefaultDeliveryRegion } = this.defaultDeliveryRegion
      const { data: { data } } = await $axios.patch(`${siteDomain}/api/delivery/v1/regions/${id}`, newDefaultDeliveryRegion)
      const response: DefaultDeliveryRegion = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  @Action({
    rawError: true
  })
  async removeDefaultDeliveryRegion (args: {regionId: number, siteDomain: string}) {
    try {
      const { regionId, siteDomain } = args
      const { data: { data } } = await $axios.delete(`${siteDomain}/api/delivery/v1/regions/${regionId}`)
      const response: DefaultDeliveryRegion = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
