
import { Component, mixins, Watch } from 'nuxt-property-decorator'
import IconDatepicker from './icon-datepicker/index.vue'
import Checklist from '~/components/pages/index/tasks/form/checklist.vue'
import { TasksMixin, TaskStatusesMixin, TaskUserOptionsMixin } from '~/store/mixins'
import { ResponseData } from '~/types/store/store-base-type'
import { Task } from '~/store/modules/tasks/store/tasks/interfaces'
import { defaultData } from '~/store/interfaces'
import { TaskStatus } from '~/store/modules/tasks/store/task-statuses/interfaces'

  @Component({
    mixins: [TasksMixin, TaskStatusesMixin, TaskUserOptionsMixin],
    components: { Checklist, IconDatepicker }
  })
export default class Tasks extends mixins(TasksMixin, TaskStatusesMixin, TaskUserOptionsMixin) {
  /**
   * *________________ Data ______________________
   */
  tasks: ResponseData<Task> = defaultData
  dateRange: number[] = []
  pickerOptions = {}
  dialogVisible = false
  statuses: number[] = []
  modalVisible = false

  /**
     * *________________ Mounted ______________________
     */
  async mounted () {
    this.statuses = this.$tasks.userFilters?.statusIds?.split(',').map((i: string) => +i) ?? []
    await this.getTaskStatuses()
  }

  /**
     * *________________ Watch ______________________
     */

  @Watch('statuses')
  async onChangeStatuses (newValue: number[]) {
    if (!newValue.length) {
      this.statuses = this.$tasks.statuses.list.data.map((item: TaskStatus) => item.id)
      return
    }
    try {
      this.$tasks.userFilters = { ...this.$tasks.userFilters, statusIds: this.statuses.join(',') }
      await this.getUserTasks()
    } catch (e) {
      console.error(e)
    }
  }

  /**
     * *________________ Methods ______________________
     */

  async updateTaskHandler (task: Task = this.$tasks.item) {
    try {
      await this.editTask(task.id, task)
      await this.getUserTasks()
    } catch (e) {
      console.error(e)
    }
  }

  changeTaskStatus (task: Task, statusId: number) {
    this.updateTaskHandler({ ...task, statusId })
  }

  openViewModal (row: Task) {
    this.$tasks.item = row
    this.modalVisible = true
  }

  tableRowClassName ({ row }: {row: Task}) {
    if (row.priority > 2) {
      return 'hotfix-row'
    }
    return ''
  }

  disabledStatus (status: TaskStatus) {
    const option = this.$tasks.options.list.data.find(item => item.params.type === 'task-statuses-roles' && item.roles.includes(this.$auth.role.name))
    return !option?.params?.statuses?.includes(status.code)
  }

  formatName (row: Task) {
    return `${row.priority > 2 ? '🔥 ' : ''} ${row.status?.params?.icon ?? ''} ${row.name}`
  }
}
