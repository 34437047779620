
import { Component, mixins } from 'nuxt-property-decorator'
import SidebarMenu from '@/components/common/sidebar/index.vue'
import HeaderComponent from '@/components/common/header/index.vue'
import ErrorLog from '@/components/common/error-log/index.vue'
import TagsView from '@/components/common/tags-view/index.vue'
import Tasks from '@/components/common/tasks/index.vue'
import Mixins from '~/store/mixins'
import { TaskUserOption } from '~/store/modules/tasks/store/task-user-options/interfaces'
import { Task } from '~/store/modules/tasks/store/tasks/interfaces'

@Component({
  components: { SidebarMenu, HeaderComponent, TagsView, ErrorLog, Tasks },
  middleware: ['auth'],
  mixins: [Mixins.TasksMixin, Mixins.TaskUserOptionsMixin, Mixins.TaskOptionsMixin]
})
export default class DefaultLayout extends mixins(Mixins.TasksMixin, Mixins.TaskUserOptionsMixin, Mixins.TaskOptionsMixin) {
  public head () {
    return {
      link: [{ rel: 'icon', type: 'image/x-icon', href: this.$config.S3_DOMAIN + '/icons/favicon.ico' }]
    }
  }

  /**
   * *________________ Data ______________________
   */
  sideBar = false
  errorDialogVisible = false
  tasksDialogVisible = false
  deadlineReminder = false

  notificationReminders: any[] = []
  alerts: any[] = []

  /**
   * *________________ Mounted ______________________
   */
  async mounted () {
    // @ts-ignore
    this.sideBar = !!this.$refs.sidebar?.isCollapse
    const errorLogs_inStorage = localStorage.getItem('errorLogs')
    const arrErrorLogs_inStorage = errorLogs_inStorage ? JSON.parse(errorLogs_inStorage) : []
    if (arrErrorLogs_inStorage && arrErrorLogs_inStorage.length > 50) {
      const itemsToDel = arrErrorLogs_inStorage.length - 50
      arrErrorLogs_inStorage.splice(0, itemsToDel)
    }
    this.$errorLogs.errorLogs = arrErrorLogs_inStorage
    localStorage.setItem('errorLogs', JSON.stringify(this.$errorLogs.errorLogs))
    await this.getTasksData()
    this.deadlineTasksReminder()
    this.notificationsReminder()
  }

  /**
   * *________________ Methods ______________________
   */
  notificationsReminder () {
    this.notificationReminders = JSON.parse(localStorage.getItem('notifications') || '[]')
    const checkNotification = () => {
      this.alerts = []
      this.notificationReminders.map((item: any) => {
        if (item.date < Date.now() / 1000) {
          this.alerts = [...this.alerts, { ...item, date: this.$format.dateTime(item.date) }]
        }
      })
    }
    checkNotification()
    setInterval(checkNotification, 30 * 1000)
  }

  deadlineTasksReminder () {
    let lastRemind = +(localStorage.getItem('lastRemind') || 0)

    const reminderTimeout = this.$tasks.userOptions.list.data.find(item => item.code === 'task-notification-timeout')?.params.timeout ?? ''
    if (!reminderTimeout) { return }

    const statuses = this.$tasks.options.list.data.find(item => item.params.type === 'task-notification-statuses' && item.roles.includes(this.$auth.role.name))?.params.statuses ?? []
    const checkDeadline = async () => {
      try {
        const now = Date.now() / 1000
        if (now - lastRemind > reminderTimeout) {
          const tasks = await this.getTasks({ statusIds: statuses.join(',') ?? undefined, executorId: this.$auth.profile.id }, false)
          this.deadlineReminder = tasks.data.some(item => item.endDate < now)
          lastRemind = now
          localStorage.setItem('lastRemind', now.toString())
          setTimeout(() => {
            this.deadlineReminder = false
          }, 10 * 1000)
        }
      } catch (e) {
        console.error(e)
      }
    }
    checkDeadline()
    setInterval(checkDeadline, 30 * 1000)
  }

  removeRemind (id: number) {
    this.notificationReminders = this.notificationReminders.filter(item => item.id !== id)
    this.alerts = this.alerts.filter(item => item.id !== id)
    localStorage.setItem('notifications', JSON.stringify(this.notificationReminders))
  }

  addRemind (task: Task, value: {title: string, date: number}) {
    this.notificationReminders.push({ title: value.title, date: value.date, id: Date.now() })
    localStorage.setItem('notifications', JSON.stringify(this.notificationReminders))
    this.$notify.success('Напоминание добавлено')
  }

  async getTasksData () {
    try {
      await Promise.all([this.getTaskOptions(), this.getTaskUserOptions()])
      const option = this.$tasks.options.list.data.find(item => item.params.type === 'view-all-tasks-roles')
      const canViewAllTasks = option?.roles.includes(this.$auth.role.name) ?? false
      const activeStatuses = this.$tasks.userOptions.list.data.find((item: TaskUserOption) => item.code === 'task-statuses-active')
      const wantViewAllTasks = this.$tasks.userOptions.list.data.find((item: TaskUserOption) => item.code === 'task-view-all')?.params?.value ?? false

      this.$tasks.userFilters = { ...activeStatuses?.params?.statuses ? { statusIds: activeStatuses?.params.statuses.join(',') } : {}, executorId: canViewAllTasks && wantViewAllTasks ? undefined : this.$auth.profile.id }
      await this.getUserTasks()
    } catch (e: any) {
      console.error(e)
    }
  }

  changeSideBar (status: boolean) {
    this.sideBar = !!status
  }

  openErrorLog () {
    this.errorDialogVisible = true
    const errorLogs_inStorage = localStorage.getItem('errorLogs')
    this.$errorLogs.errorLogs = errorLogs_inStorage ? JSON.parse(errorLogs_inStorage).map((errorLog:any) => { return { ...errorLog, viewed: true } }) : []
    localStorage.setItem('errorLogs', JSON.stringify(this.$errorLogs.errorLogs))
  }

  openTasksTable () {
    this.tasksDialogVisible = true
  }

  resetErrorLogs () {
    this.$errorLogs.resetErrorLogs()
    this.errorDialogVisible = false
  }
}
