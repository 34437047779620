import { Context } from '@nuxt/types'
import { UsersPlugin, User, FiltersParams, UserPassword, TokenParams } from './interfaces'
import UsersStore from './store'
import { PageParams, ResponseData, ValidatorParams } from '~/store/interfaces'
export default function getPluginApi (this: Context, usersStore: UsersStore) {
  const ctx = this

  const api: UsersPlugin = {
    // ? ________ getters ________

    get userPassword (): UserPassword {
      return usersStore.userPassword
    },

    get filters (): FiltersParams {
      return usersStore.filters
    },
    get userList (): ResponseData<User> {
      return usersStore.userList
    },
    get validators (): ValidatorParams {
      return usersStore.validators
    },
    userById (id: number): User | undefined {
      return usersStore.userById(id)
    },
    get currentUser (): User {
      return usersStore.currentUser
    },

    // ? ________ setters ________

    set userPassword (passwords: UserPassword) {
      usersStore.setUserPassword(passwords)
    },

    set filters (filters: FiltersParams) {
      usersStore.setFilters(filters)
    },

    set userList (users: ResponseData<User>) {
      usersStore.setUsersList(users)
    },

    resetUserPassword () {
      usersStore.resetUserPassword()
    },

    resetCurrentUser () {
      usersStore.resetCurrentUser()
    },

    resetUsers () {
      usersStore.resetUsers()
    },

    resetFilters () {
      usersStore.resetFilters()
    },

    set currentUser (user: User) {
      usersStore.setCurrentUser(user)
    },

    // ? ________ actions ________
    getUserTokens (id: number): Promise<TokenParams> {
      return usersStore.getUserTokens(id)
    },

    async getUsers (pageParams?: PageParams, save = true): Promise<ResponseData<User>> {
      const data = await usersStore.getUsers(pageParams)
      save && usersStore.setUsersList(data)
      return data
    },

    getUser (id: number): Promise<User> {
      return usersStore.getUser(id)
    },

    removeUser (id: number): Promise<User> {
      return usersStore.removeUser(id)
    },

    editUser (): Promise<User> {
      return usersStore.editUser(ctx.$config.SITE_DOMAIN)
    },

    changePassword (): Promise<User> {
      return usersStore.changePassword()
    },

    recoverData (): Promise<User> {
      return usersStore.recoverData()
    },

    createUser (): Promise<User> {
      return usersStore.createUser()
    },
    exportExcel (pageParams: PageParams | null = null): Promise<Blob> {
      return usersStore.exportExcel(pageParams)
    },
    getUserWithoutCommit (id: number): Promise<User> {
      return usersStore.getUser(id)
    }
  }
  return api
}
