import { Context } from '@nuxt/types'
import { ResponseData, PageParams } from '../../interfaces'
import { Filters, Manager, ManagersPlugin } from './interfaces'

import ManagersModule from './store'

export default function getPluginApi (store: ManagersModule) {
  const ctx: Context = this
  const api: ManagersPlugin = {
    get filters (): Filters {
      return store.filters
    },

    get managers (): ResponseData<Manager> {
      return store.managers
    },

    get manager () {
      return store.manager
    },

    // ? ________ setters ________
    set filters (filters: Filters) {
      store.setFilters(filters)
    },
    set managers (managers: ResponseData<Manager>) {
      store.setManagersValue(managers)
    },
    set manager (manager: any) {
      store.setManagerValue(manager)
    },

    resetManager () {
      store.resetManagerValue()
    },
    resetFilters () {
      store.resetFilters()
    },

    // ? ________ actions ________
    getManagers (pageParams?: PageParams): Promise<Manager[]> {
      return store.getManagers({ siteApiUrl: ctx.$config.SITE_API, pageParams })
    },

    getManager (id: number): Promise<Manager> {
      return store.getManager({ siteApiUrl: ctx.$config.SITE_API, id })
    },

    createManager (manager: Manager): Promise<Manager> {
      return store.createManager({ siteApiUrl: ctx.$config.SITE_API, manager })
    },

    editManager (id: number, manager: Manager): Promise<Manager> {
      return store.editManager({ siteApiUrl: ctx.$config.SITE_API, id, manager })
    },

    deleteManager (id: number): Promise<Manager> {
      return store.deleteManager({ siteApiUrl: ctx.$config.SITE_API, id })
    }
  }
  return api
}
