
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'nuxt-property-decorator'
import { ChecklistItem } from '~/store/modules/tasks/store/tasks/interfaces'

@Component({})
export default class Checklist extends Vue {
  @Prop({
    type: Boolean,
    required: false,
    default: () => false
  })
    readonly!: boolean

  addTodo () {
    this.$tasks.setItemProp('checklist', [...this.$tasks.item.checklist, {
      name: '',
      code: '',
      checked: false
    }])
  }

  updateTodo <K extends keyof ChecklistItem, V extends ChecklistItem[K]> (i: number, key: K, value: V) {
    this.$tasks.setItemProp('checklist', this.$tasks.item.checklist.map((item, idx) => i === idx ? { ...item, [key]: value } : item))
    this.$emit('update:checklist')
  }

  removeTodo (i: number) {
    this.$tasks.setItemProp('checklist', this.$tasks.item.checklist.filter((_, idx) => i !== idx))
  }
}
