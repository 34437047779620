<template>
  <div>
    <el-popover
      v-model="visible"
      placement="bottom"
      trigger="click"
    >
      <el-tooltip slot="reference" content="Установить напоминание" effect="dark" placement="top">
        <el-button type="text">
          <i class="el-icon-alarm-clock" />
        </el-button>
      </el-tooltip>
      <el-input
        v-model="title"
        placeholder="Позвонить кому-то"
        style="width: initial;"
      />
      <el-date-picker
        ref="datepicker"
        v-model="localValue"
        type="datetime"
        :picker-options="pickerOptions"
        placeholder="Выберите дату и время"
        value-format="timestamp"
        default-time="9:00:00"
      />
      <div style="text-align: right; margin-top: 5px;">
        <el-button size="mini" type="text" @click="visible = false">
          Отмена
        </el-button>
        <el-button type="primary" size="mini" :disabled="disabled" @click="onChooseTime">
          Добавить напоминание
        </el-button>
      </div>
    </el-popover>
  </div>
</template>

<script type="text/babel">
import { nextDate } from 'element-ui/lib/utils/date-util'
import { Component, Prop, Watch } from 'nuxt-property-decorator'
import Vue from 'vue'

@Component({})
export default class IconDatepicker extends Vue {
    @Prop({
      type: String,
      default: ''
    })
      taskTitle

    title = ''

    localValue = nextDate(new Date(), 1).setHours(9, 0, 0)

    visible = false

    mounted () {
      this.title = `${this.taskTitle}`
    }

    pickerOptions = {
      firstDayOfWeek: 1,
      disabledDate (time) {
        return time.getTime() < new Date().setHours(0, 0, 0, 0)
      }
    }

    get disabled () {
      return !this.localValue || this.localValue < new Date().getTime() || !this.title
    }

  @Watch('visible')
    onChangeVisible () {
      this.localValue = nextDate(new Date(), 1).setHours(9, 0, 0)
      this.title = `${this.taskTitle}`
    }

  onChooseTime () {
    if (this.disabled) { return }
    this.$emit('chooseTime', { title: this.title, date: this.localValue / 1000 })

    this.visible = false
  }
}
</script>

<style scoped>
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}
</style>
