import { Context } from '@nuxt/types'
import { Inject } from '@nuxt/types/app'
import { AxiosError } from 'axios'
import { FormError } from '~/store/interfaces'
import { NDataViewer } from '~/types/data-viewer'

export default ({ $axios, $config }: Context, inject: Inject) => {
  inject('dataViewer', {
    fetch: async function <T> (schema: NDataViewer.IRequestSchema<T>, format: NDataViewer.TDataFormat = 'json') {
      try {
        return (await $axios.post(
          `${$config.SITE_API}/data-viewer/v1` + (format !== 'json' ? '/xlsx' : ''),
          schema,
          {
            responseType: format !== 'json' ? 'blob' : 'json'
          }
        ))?.data
      } catch (error) {
        console.error(error)
        throw new FormError(error as AxiosError<FormError>)
      }
    }
  })
}
