import { Action, Module, Mutation } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { Task } from './interfaces'
import BaseModule from '~/types/store/store-base'
import { IFilters, ModuleConfig, PageParams, ResponseData } from '~/types/store/store-base-type'
import { defaultData } from '~/types/store/constants'
import { $axios } from '~/utils/api'
import { FormError } from '~/store/interfaces'

@Module({
  stateFactory: true,
  namespaced: true,
  name: 'tasks'
})
export default class TasksModule extends BaseModule<Task, 'id', keyof Task> {
  protected config: ModuleConfig<Task, 'id'> = {
    apiUrl: '/task',
    identifier: 'id'
  }

  protected itemData: Task = {
    id: 0,
    name: '',
    description: '',
    managerId: 0,
    executorId: null,
    statusId: 1,
    priority: 2,
    link: '',
    linkText: '',
    startDate: Date.now() / 1000,
    endDate: +new Date(Date.now() + (24 * 60 * 60 * 1000)) / 1000,
    createdAt: 0,
    updatedAt: 0,
    checklist: [],
    params: {}
  }

    @Mutation
  resetItem () {
    this.itemData = {
      id: 0,
      name: '',
      description: '',
      managerId: 0,
      executorId: null,
      statusId: 1,
      priority: 2,
      link: '',
      linkText: '',
      startDate: Date.now() / 1000,
      endDate: +new Date(Date.now() + (24 * 60 * 60 * 1000)) / 1000,
      createdAt: 0,
      updatedAt: 0,
      checklist: [],
      params: {}
    }
  }

    protected userFiltersList: IFilters<Task, keyof Task> = {}
    protected userTasksList: ResponseData<Task> = defaultData

    get userFilters () {
      return this.userFiltersList
    }

    get userTasks () {
      return this.userTasksList
    }

  @Mutation
    setUserTasks (data: ResponseData<Task>) {
      this.userTasksList = data
    }

  @Mutation
  setUserFilters (data: IFilters<Task, keyof Task>) {
    this.userFiltersList = data
  }

  @Action({
    rawError: true
  })
  async getUserTasksList (pageParams?: PageParams<Task>) {
    try {
      const { data } = await $axios.get<ResponseData<Task>>(
        `${this.config.apiUrl}`,
        {
          params: { ...this.userFiltersList, ...pageParams },
          headers: {
            common: {
              apiUrlPrefixEnv: this.config.apiUrlPrefixEnv || ''
            }
          }
        }
      )
      return data
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
