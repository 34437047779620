import { Component } from 'nuxt-property-decorator'
import { Task } from './interfaces'
import StoreMixin from '~/mixins/store-mixin'
import { PageParams } from '~/types/store/store-base-type'
import { ValidatorParams } from '~/store/interfaces'
import validatorsPattern from '~/utils/validators'

@Component({
  name: 'TasksMixin'
})
export default class extends StoreMixin {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    // Запуск лоадера перехода по страницам
    this.$wait.start('leaveRouter')
    this.$tasks.resetItem()
    next()
  }

  getTasks (pageParams: PageParams<Task> = {}, save = true) {
    return this.asyncRequestHandler({
      loader: save ? 'getTasks' : ''
    },
    this.$tasks.getList(pageParams, save)
    )
  }

  getUserTasks (pageParams: PageParams<Task> = {}, save = true) {
    return this.asyncRequestHandler({
      loader: save ? 'getUserTasks' : '.'
    },
    this.$tasks.getUserTasks(pageParams, save)
    )
  }

  getTask (id: Task['id'], save = true) {
    return this.asyncRequestHandler(
      { loader: 'getTask' },
      this.$tasks.getItem(id,
        save)

    )
  }

  createTask () {
    return this.asyncRequestHandler(
      {
        loader: 'createTask',
        message: (task: Task) => `Задача ${task.name} создана`
      },
      this.$tasks.createItem()
    )
  }

  editTask (id: Task['id'] = this.$tasks.item.id, task: Task = this.$tasks.item) {
    return this.asyncRequestHandler(
      {
        loader: 'editTask',
        message: (task: Task) => `Задача ${task.name} изменена`
      },
      this.$tasks.editItem(id, task)

    )
  }

  removeTask (id: Task['id']) {
    return this.asyncRequestHandler(
      {
        loader: 'removeTask',
        message: (task: Task) => `Задача ${task.name} удалена`
      },
      this.$tasks.removeItem(id)

    )
  }

  validators: ValidatorParams = {
    name: [{
      required: true,
      pattern: validatorsPattern.stringEmpty,
      message: 'Введите название задачи',
      trigger: ['blur']
    }],
    executorId: [{
      type: 'number',
      required: true,
      message: 'Выберите исполнителя',
      trigger: ['blur']
    }],
    statusId: [{
      type: 'number',
      required: true,
      message: 'Выберите статус задачи',
      trigger: ['blur']
    }],
    description: [{
      required: true,
      pattern: validatorsPattern.emptyStringEmpty,
      message: 'Введите текст задачи',
      trigger: ['blur']
    }]
    // page: [{
    //   pattern: validatorsPattern.naturalNumbers,
    //   message: 'Введите натуральное число',
    //   trigger: ['blur', 'change']
    // }]
  }
}
