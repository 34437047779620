import { Context } from '@nuxt/types'
import { DefaultDeliveryRegionsPlugin, DefaultDeliveryRegion, Params } from './interfaces'
import DefaultDeliveryRegionsModule from './store'
import { ResponseData, ValidatorParams } from '~/store/interfaces'

export default function getPluginApi (this: Context, store: DefaultDeliveryRegionsModule) {
  const ctx = this

  const api: DefaultDeliveryRegionsPlugin = {
    // ? ________ getters ________

    get defaultDeliveryRegion (): DefaultDeliveryRegion {
      return store.defaultDeliveryRegion
    },

    get defaultDeliveryRegions (): ResponseData<DefaultDeliveryRegion> {
      return store.defaultDeliveryRegions
    },

    get validators (): ValidatorParams {
      return store.validators
    },

    defaultDeliveryRegionById (id: number): DefaultDeliveryRegion | undefined {
      return store.defaultDeliveryRegionById(id)
    },

    // ? ________ setters ________

    set defaultDeliveryRegion (defaultDeliveryRegion: DefaultDeliveryRegion) {
      store.setDefaultDeliveryRegion(defaultDeliveryRegion)
    },

    set defaultDeliveryRegions (defaultDeliveryRegions: ResponseData<DefaultDeliveryRegion>) {
      store.setDefaultDeliveryRegions(defaultDeliveryRegions)
    },

    resetDefaultDeliveryRegion () {
      store.resetDefaultDeliveryRegion()
    },

    // ? ________ actions ________
    getDefaultDeliveryRegions (params: Params): Promise<ResponseData<DefaultDeliveryRegion>> {
      return store.getDefaultDeliveryRegions({ params, siteDomain: ctx.$config.SITE_DOMAIN })
    },

    getDefaultDeliveryRegionById (regionId: number) {
      return store.getDefaultDeliveryRegionById({ regionId, siteDomain: ctx.$config.SITE_DOMAIN })
    },

    createDefaultDeliveryRegion (optionId: number) {
      return store.createDefaultDeliveryRegion({ optionId, siteDomain: ctx.$config.SITE_DOMAIN })
    },

    editDefaultDeliveryRegion () {
      return store.editDefaultDeliveryRegion(ctx.$config.SITE_DOMAIN)
    },

    removeDefaultDeliveryRegion (regionId: number) {
      return store.removeDefaultDeliveryRegion({ regionId, siteDomain: ctx.$config.SITE_DOMAIN })
    }

  }

  return api
}
