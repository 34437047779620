import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { FormError, defaultData, PageParams, ResponseData } from '../../interfaces'
import {
  Orders,
  Order,
  Filters,
  ValidatorParams,
  PriceParams,
  ExcelType,
  EditOrderRequestData,
  Buyer,
  Log
} from './interfaces'
import { $axios } from '~/utils/api'
import { dateOneDayLater } from '~/utils/date'

@Module({
  name: 'orders',
  stateFactory: true,
  namespaced: true
})
export default class OrdersModule extends VuexModule {
  /**
   * * Массив всех order исходя из запроса
   */
  orders: Orders = {
    prices: {
      price: 0,
      full_price: 0
    },
    countNew: 0,
    ...defaultData
  }

  /**
   * * Текущий order
   */
  order: Order = {
    id: 0,
    siteId: 1,
    paymentDiscount: null,
    profile: {
      userId: 0,
      email: ''
    },
    shipping: {
      contactPerson: '',
      phone: '',
      email: '',
      zip: '',
      address: {
        city: '',
        country: '',
        house: '',
        region: '',
        street: '',
        value: ''
      },
      isDefault: false,
      kladrId: ''
    },
    deliveryData: {
      code: '',
      price: 0,
      basePrice: 0,
      options: {
        code: '',
        types: {
          code: '',
          name: ''
        }
      },
      isDefault: false
    },
    orderOffers: [],
    paymentId: 0,
    bonus: 0,
    freeDeliverySum: 0,
    qualityEvaluationStatusId: undefined,
    statusId: 0,
    deliveryMargin: 0,
    statusGroupsId: 0,
    trackId: null,
    condition: 0,
    email: '',
    prices: {
      price: 0,
      oldPrice: 0,
      margin: 0,
      promoDiscount: 0,
      discountWithSetDiscount: 0
    },
    params: {},
    isPayed: false
  }

  /**
   * * Фильтры
   */
  filterValue: Filters = {
    page: undefined,
    pageSize: undefined,
    id: undefined,
    statusId: undefined,
    fio: undefined,
    role: undefined,
    statusGroupsId: undefined,
    track: undefined,
    email: undefined,
    phone: undefined,
    dateFrom: undefined,
    dateTo: undefined,
    userId: undefined,
    isPayed: undefined,
    ordersCount: undefined,
    productsCount: undefined,
    priceSum: undefined,
    sync: undefined
  }

  buyerValue: ResponseData<Buyer> = defaultData

  logsValue: ResponseData<Log> = defaultData

  // ? ______________ getters ______________
  /**
   * * Получить массив orders и пагинацией
   */
  get ordersList (): Orders {
    return this.orders
  }

  /**
   * * Получить order из массива orders
   */
  get orderById () {
    const orders = this.orders
    return function (id: number): Order | undefined {
      return orders.data.find(order => order.id === id)
    }
  }

  /**
   * * Валидна ли форма
   */
  get validateData (): boolean {
    return !!(this.order.profile?.phone && this.order.deliveryData)
  }

  /**
   * * Фильтры
   */
  get filters (): Filters {
    return this.filterValue
  }

  /**
   * * Получить текущий order для измения или создания order
   */
  get currentOrder () {
    return this.order
  }

  get buyer () {
    return this.buyerValue
  }

  get validators (): ValidatorParams {
    return {
      email: [
        {
          type: 'email',
          required: true,
          message: 'Введите корректный email',
          trigger: ['blur']
        }
      ],
      phone: [
        {
          type: 'string',
          pattern: /^\+?[1-9]\d{1,14}$/,
          required: true,
          message: 'Введите номер телефона',
          trigger: ['change', 'blur']
        }
      ],
      deliveryMargin: [
        {
          type: 'number',
          pattern: /^-?[0-9]*[.|,]?[0-9]{0,2}$/,
          message: 'Введите корректную поправку'
        }
      ],
      deliveryData: {
        pickupPointAddress: [
          {
            type: 'string',
            required: true,
            message: 'Выберите адрес пункта выдачи',
            trigger: ['change', 'blur']
          }
        ],
        code: [
          {
            type: 'string',
            required: true,
            min: 1,
            message: 'Выберите способ доставки',
            trigger: ['change', 'blur']
          }
        ],
        options: {
          code: [
            {
              type: 'string',
              required: true,
              min: 1,
              message: 'Выберите транспортную компанию',
              trigger: ['change', 'blur']
            }
          ],
          types: {
            name: [
              {
                type: 'string',
                required: true,
                message: 'Выберите адрес пункта выдачи',
                trigger: ['change', 'blur']
              }
            ],
            code: [
              {
                type: 'string',
                required: true,
                message: 'Выберите тип доставки',
                trigger: ['change', 'blur']
              }
            ]
          }
        }
      },
      bonus: [
        {
          pattern: /^[0-9]*[.|,]?[0-9]{0,2}$/,
          message: 'Введите корректное значение бонуса'
        }
      ],
      shipping: {
        // Правила валидации адреса доставки
        address: {
          // Полный адреес
          value: [
            {
              type: 'string',
              required: true,
              message: 'Введите адрес доставки',
              trigger: ['change', 'blur']
            }
          ],
          // Дом
          house: [
            {
              type: 'string',
              required: true,
              message: 'Введите полный адрес',
              trigger: ['change', 'blur']
            }
          ],
          // Город
          city: [
            {
              type: 'string',
              required: true,
              message: 'Выберите город',
              trigger: ['change', 'blur']
            }
          ]
        },
        phone: [
          {
            type: 'string',
            pattern: /^\+?[1-9]\d{1,14}$/,
            message: 'Введите номер телефона',
            trigger: ['change', 'blur']
          }
        ],
        email: [
          {
            type: 'email',
            message: 'Введите корректный email',
            trigger: ['change', 'blur']
          }
        ],
        otherPhone: [
          {
            type: 'string',
            pattern: /^\+?[1-9]\d{1,14}$/,
            message: 'Введите номер телефона',
            trigger: ['change', 'blur']
          }
        ]
      }
    }
  }

  get logs (): ResponseData<Log> {
    return this.logsValue
  }

  // ? ______________ setters ______________
  /**
   * * Установить массив Orders
   * @param orders массив Orders
   */
  @Mutation
  setOrdersList (orders: Orders) {
    this.orders = orders
  }

  /**
   * * Установить CurrentOrder для измения или создания order
   * @param order текущая Order, которую мы изменяем или создаем
   */
  @Mutation
  setCurrentOrder (order: Order) {
    this.order = order
  }

  /**
   * * Установить фильтры
   * @param filters
   */
  @Mutation
  setFilters (filters: Filters) {
    this.filterValue = filters
  }

  @Mutation
  setBuyerValue (val: any) {
    this.buyerValue = val
  }

  /**
   * * Обнулить форму редактирования или создания
   */
  @Mutation
  resetCurrentOrder () {
    this.order = {
      id: 0,
      siteId: 1,
      paymentDiscount: null,
      profile: {
        userId: 0,
        email: ''
      },
      shipping: {
        contactPerson: '',
        phone: '',
        email: '',
        zip: '',
        address: {
          city: '',
          country: '',
          house: '',
          region: '',
          street: '',
          value: ''
        },
        isDefault: false,
        kladrId: ''
      },
      deliveryData: {
        code: '',
        price: 0,
        basePrice: 0,
        options: {
          code: '',
          types: {
            code: ''
          }
        },
        isDefault: false
      },
      paymentId: 0,
      statusId: 0,
      deliveryMargin: 0,
      qualityEvaluationStatusId: undefined,
      bonus: 0,
      statusGroupsId: 0,
      trackId: null,
      condition: 0,
      prices: {
        price: 0,
        oldPrice: 0,
        margin: 0,
        promoDiscount: 0,
        discountWithSetDiscount: 0
      },
      orderOffers: [],
      freeDeliverySum: 0,
      email: '',
      params: {},
      isPayed: false
    }
  }

  /**
   * * Обнулить ordersList
   */
  @Mutation
  resetOrders () {
    this.orders = {
      prices: {
        price: 0,
        full_price: 0
      },
      countNew: 0,
      ...defaultData
    }
  }

  /**
   * * Очистить Фильтры
   */
  @Mutation
  resetFilters () {
    this.filterValue = {}
  }

  /**
   * установить цену
   * @param prices цена и цена со скидкой в заказе
   */
  @Mutation
  setCurrentOrderPrice (prices: PriceParams) {
    this.order = { ...this.order, prices }
  }

  @Mutation
  setLogs (logs: any) {
    this.logsValue = logs
  }
  // ? ______________________________________actions______________________________________

  @Action({
    rawError: true,
    commit: 'setBuyerValue'
  })
  async getBuyer ({
    siteApiUrl,
    pageParams
  }: {
        siteApiUrl: string;
        pageParams: PageParams
      }) {
    try {
      const { data } = await $axios.get(`${siteApiUrl}/orders/v1/admin/buyers`, {
        params: {
          ...this.filterValue,
          ...pageParams,
          dateTo: this.filterValue.dateTo
            ? dateOneDayLater(this.filterValue.dateTo)
            : undefined
        }
      })
      const response: Buyer[] = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  @Action({
    rawError: true
  })
  async getBuyerXlsx ({
    siteApiUrl,
    pageParams
  }: {
        siteApiUrl: string;
        pageParams: PageParams
      }) {
    try {
      const { data } = await $axios.get(`${siteApiUrl}/orders/v1/admin/buyers/xlsx`, {
        params: {
          ...this.filterValue,
          ...pageParams,
          dateTo: this.filterValue.dateTo
            ? dateOneDayLater(this.filterValue.dateTo)
            : undefined
        },
        responseType: 'blob'
      })
      const response = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Получить список Orders по параметрам запроса
   * @param pageParams параметры запроса
   */
  @Action({
    rawError: true,
    commit: 'setOrdersList'
  })
  async getOrders (pageParams: PageParams) {
    try {
      const { data } = await $axios.get('/shop/orders', {
        params: {
          ...this.filterValue,
          ...pageParams,
          dateTo: this.filterValue.dateTo
            ? dateOneDayLater(this.filterValue.dateTo)
            : undefined
        }
      })
      const response: Orders = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Получить order по id
   * @param id id order которую мы хотим получить
   */
  @Action({
    rawError: true,
    commit: 'setCurrentOrder'
  })
  async getOrder (id: number) {
    try {
      const {
        data: { data }
      } = await $axios.get(`/shop/orders/${id}`)
      const response: Order = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Изменить order
   */
  @Action({
    rawError: true
  })
  async editOrder ({ siteApiUrl, id, data } : { siteApiUrl: string, id: number, data: EditOrderRequestData }) {
    try {
      await $axios.patch<void>(`${siteApiUrl}/orders/v1/${id}`, data)
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Создать Order
   * @param userId  id user на которого мы хотим слздать order
   * @param siteApiUrl
   */
  @Action({
    rawError: true
  })
  async createOrder ({ userId, siteApiUrl }: {userId: number, siteApiUrl: string}) {
    try {
      const { data } = await $axios.post(`${siteApiUrl}/orders/v1/empty`, { userId })
      const response: {orderId: number} = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Удалить order по id
   * @param id  id order которую мы хотим удалить
   */
  @Action({
    rawError: true
  })
  async removeOrder (id: number) {
    try {
      const {
        data: { data }
      } = await $axios.delete(`/shop/orders/${id}`)
      const response: Order = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Получить цену заказа
   */
  @Action({
    rawError: true
  })
  async getOrderPrice (siteApiUrl: string) {
    try {
      const {
        data
      } = await $axios.get<{
        deliveryPrice: { basePrice: number, price: number, margin: number },
        discountWithSetDiscount: number,
        margin: number,
        oldPrice: number,
        price: number,
      }>(`${siteApiUrl}/orders/v1/${this.currentOrder.id}/price`)
      this.setCurrentOrder({
        ...this.order,
        deliveryMargin: data.deliveryPrice.margin,
        prices: {
          ...this.order.prices,
          discountWithSetDiscount: data.discountWithSetDiscount,
          margin: data.margin,
          oldPrice: data.oldPrice,
          price: data.price
        }
      })
      return data
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Выгрузить в excel
   * @param reqType роут, по которому необходимо выполнить запрос
   * @param pageParams параметры фильтрации
   * @returns excel файл
   */
  @Action({
    rawError: true
  })
  async exportExcel (reqType: ExcelType = ExcelType.EXCEL, pageParams?: PageParams | null) {
    try {
      const { data } = await $axios.get(`/shop/orders/${reqType}`, {
        params: {
          ...pageParams,
          ...this.filterValue,
          dateTo: this.filterValue.dateTo
            ? dateOneDayLater(this.filterValue.dateTo)
            : undefined
        },
        responseType: 'blob'
      })
      return data
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Применить скидки к товарам в заказе
   * @param {discount, id} - id заказа, discount скидка в процентах
   */
  @Action({
    rawError: true
  })
  async applyDiscount ({ discount, id, siteApiUrl }: { discount: number; id: number, siteApiUrl: string }): Promise<any> {
    try {
      const { data } = await $axios.post(`${siteApiUrl}/orders/v1/${id}/discount`, {
        discount
      })
      return data
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  @Action({
    rawError: true,
    commit: 'setLogs'
  })
  async getLogs ({
    siteApiUrl,
    entity,
    id,
    pageParams
  }: {
        siteApiUrl: string;
        entity: string
        id: number
        pageParams: PageParams
      }) {
    try {
      const { data } = await $axios.get(`${siteApiUrl}/entity-logs/v1/${entity}/${id}/logs`, {
        params: { ...pageParams }
      })
      const response = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
